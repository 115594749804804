import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import moment from 'moment'
// @ts-ignore
import { Md5 } from 'ts-md5/dist/md5'
import BigNumber from 'bignumber.js'
import Company from './Company'
import OpportunityActivity from './OpportunityActivity'
import Model from './interface/Model'
import ModelWithFiles from './interface/ModelWithFiles'
import Api from './Api'
import { LostCategories } from './interface/Common'
import ModelFile from './ModelFile'
import PaginateOptions from './interface/PaginateOptions'
import WebMessage from './WebMessage'

export default class Opportunity extends ModelWithFiles {
  protected api_settings = {
    save_mode: 'form',
    paths: {
      singular: 'opportunities' as string | null,
      plural: 'opportunities' as string | null,
    },
  }

  public get user(): any {
    const { user } = getModule(SystemtModule)
    return user ?? {}
  }

  public id: any = null

  public holding_id: any = null

  public agency_id: any = null

  public agency: Company | any = null

  public station_id: any = null

  public station: Company | any = null

  public advertiser_id: any = null

  public advertiser: Company | any = null

  public sales_management_id: any = this.user.manager_id

  public sales_rep_id: any = this.user.id

  public account_manager_id: any = null

  public name: any = null

  public status: any = 'targeting'

  public is_cancelled: boolean = false

  public percentage: any = 10

  public types: string[] = ['ssl']

  public source: any = 'outbound'

  public amount: any = 0

  public original_amount: any = 0

  public start_at: any = moment().startOf('month').add(1, 'month').format('YYYY-MM-DD')

  public end_at: any = moment().endOf('month').add(1, 'month').format('YYYY-MM-DD')

  public closed_at: string = ''

  public company_contact_ids: any = []

  public activities: OpportunityActivity[] = []

  public preplan_ids: any = []

  public media_plan_ids: any = []

  public main_media_plan_id: any = null

  public created_at: any = null

  public force_new_business: string = 'auto'

  public is_new_business: boolean = false

  public static opportunity_status: any = [
    {
      name: 'Targeting',
      value: 10,
      code: 'targeting',
    },
    {
      name: 'Repeat Business',
      value: 20,
      code: 'repeat_business',
    },
    {
      name: 'Pitched',
      value: 30,
      code: 'pitched',
    },
    {
      name: 'Consideration',
      value: 50,
      code: 'consideration',
    },
    {
      name: 'Expected',
      value: 75,
      code: 'expected',
    },
    {
      name: 'Processing',
      value: 90,
      code: 'processing',
    },
    {
      name: 'Won',
      value: 100,
      code: 'won',
    },
    {
      name: 'Lost',
      value: 0,
      code: 'lost',
    },
    // {
    //   name: 'Full Order CXL',
    //   value: 0,
    //   code: 'full_order_cxl',
    // },
  ]

  public static opportunity_status_text: any = [
    {
      name: 'Targeting',
      value: 'targeting',
      code: 'targeting',
    },
    {
      name: 'Repeat Business',
      value: 'repeat_business',
      code: 'repeat_business',
    },
    {
      name: 'Pitched',
      value: 'pitched',
      code: 'pitched',
    },
    {
      name: 'Consideration',
      value: 'consideration',
      code: 'consideration',
    },
    {
      name: 'Expected',
      value: 'expected',
      code: 'expected',
    },
    {
      name: 'Processing',
      value: 'processing',
      code: 'processing',
    },
    {
      name: 'Won',
      value: 'won',
      code: 'won',
    },
    {
      name: 'Lost',
      value: 'lost',
      code: 'lost',
    },
  ]

  public static option_list: any = {
    lost_categories: LostCategories,
    lead_sources: [
      {
        name: 'lead_source',
        value: 'lead_source',
      },
    ],
    mrgs: [
      {
        name: 'Zone',
        value: 'zone',
      },
      {
        name: 'LMA',
        value: 'lma',
      },
    ],
  }

  public tasks: any = {
    request_max_avail_plan: false, // at the backend, this will create a task for the sales rep
    request_media_plan: false,
  }

  public metadata: any = {
    overwride_distribution: false,
    product_revenue_distribution: {
      ssl: 0,
      ccl: 0,
    },
    period_revenue_distribution: {
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
    },
    monthly_revenue_distribution: {
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,
    },
  }

  public get companies() {
    let ids = []

    if (this.agency_id) ids.push(this.agency_id)
    if (this.advertiser_id) ids.push(this.advertiser_id)

    return ids
  }

  public get formAmount() {
    return this.amount
  }

  public set formAmount(val) {
    this.amount = Number(val)

    if (this.amount === 0) {
      this.metadata.product_revenue_distribution.ssl = 0
      this.metadata.product_revenue_distribution.ccl = 0
      return
    }

    if (this.types.length == 1) {
      if (this.types[0] == 'ssl') {
        this.metadata.product_revenue_distribution.ssl = this.amount
        this.metadata.product_revenue_distribution.ccl = 0
      } else {
        this.metadata.product_revenue_distribution.ssl = 0
        this.metadata.product_revenue_distribution.ccl = this.amount
      }
      return
    }

    let total = Number(this.sslAmount) + Number(this.cclAmount)

    if (total == 0) {
      this.metadata.product_revenue_distribution.ssl = this.amount / 2
      this.metadata.product_revenue_distribution.ccl = this.amount / 2
    } else {
      this.metadata.product_revenue_distribution.ssl = this.amount * (this.sslAmount / total)
      this.metadata.product_revenue_distribution.ccl = this.amount * (this.cclAmount / total)
    }
  }

  public get sslAmount() {
    return this.metadata.product_revenue_distribution.ssl
  }

  public set sslAmount(val) {
    if (val > this.amount) {
      val = this.amount
    }

    if (val == this.metadata.product_revenue_distribution.ssl) {
      return
    }

    this.metadata.product_revenue_distribution.ssl = val

    let diff = this.amount - val

    if (this.metadata.product_revenue_distribution.ccl != diff) {
      this.metadata.product_revenue_distribution.ccl = diff
    }
  }

  public get cclAmount() {
    return this.metadata.product_revenue_distribution.ccl
  }

  public set cclAmount(val) {
    if (val > this.amount) {
      val = this.amount
    }

    if (val == this.metadata.product_revenue_distribution.ccl) {
      return
    }

    this.metadata.product_revenue_distribution.ccl = val
    let diff = this.amount - val

    if (this.metadata.product_revenue_distribution.ssl != diff) {
      this.metadata.product_revenue_distribution.ssl = diff
    }
  }

  public get quarters() {
    const start_at = moment(this.start_at)
    const end_at = moment(this.end_at)

    return `Q${start_at.quarter()} ${start_at.year()} ~ Q${end_at.quarter()} ${end_at.year()}`
  }

  public close_reason: any = null

  public lost_category: any = null

  public rfp: any = null

  public lead_source: any = null

  public close_week_day: any = 'sunday'

  public mrg: any = null

  public max_avail_tasks: number = 0

  public media_plan_tasks: number = 0

  public is_forecast: boolean = false

  public get formTypes() {
    return this.types.map(v => v.toUpperCase()).join(', ')
  }

  // Check if object has changed
  public get is_dirty(): boolean {
    return this._hash !== this.calculateChecksum()
  }

  private _hash: string | Int32Array = ''

  private calculateChecksum(): string | Int32Array {
    let data = this.apiData

    return Md5.hashStr(JSON.stringify(data))
  }

  public updateChecksum() {
    this._hash = this.calculateChecksum()
  }

  public requestMaxAvails() {
    this.tasks.request_max_avail_plan = true
  }

  public requestMediaPlan() {
    this.tasks.request_media_plan = true
  }

  public get apiData() {
    return {
      holding_id: this.holding_id,
      agency_id: this.agency_id,
      station_id: this.station_id,
      advertiser_id: this.advertiser_id,
      sales_management_id: this.sales_management_id,
      sales_rep_id: this.sales_rep_id,
      account_manager_id: this.account_manager_id,
      name: this.name,
      status: this.status,
      percentage: this.percentage,
      types: this.types,
      source: this.source,
      amount: this.amount,
      start_at: this.start_at,
      end_at: this.end_at,
      closed_at: this.closed_at,
      company_contact_ids: this.company_contact_ids,
      preplan_ids: this.preplan_ids,
      media_plan_ids: this.media_plan_ids,
      activities: this.activities,
      tasks: this.tasks,
      close_reason: this.close_reason,
      lost_category: this.lost_category,
      rfp: this.rfp,
      lead_source: this.lead_source,
      close_week_day: this.close_week_day,
      mrg: this.mrg,
      metadata: this.metadata,
      main_media_plan_id: this.main_media_plan_id,
      original_amount: this.original_amount,
      is_cancelled: this.is_cancelled,
      is_forecast: this.is_forecast,
      force_new_business: this.force_new_business,
    }
  }

  public toObject(source: any): this {
    let instance = this.clone()

    if (source.start_at) {
      instance.start_at = source.start_at.includes('T')
        ? source.start_at.split('T')[0]
        : moment(source.start_at).format('YYYY-MM-DD')
    }

    if (source.end_at) {
      instance.end_at = source.end_at.includes('T')
        ? source.end_at.split('T')[0]
        : moment(source.end_at).format('YYYY-MM-DD')
    }

    if (source.closed_at) {
      instance.closed_at = source.closed_at.includes('T')
        ? source.closed_at.split('T')[0]
        : moment(source.closed_at).format('YYYY-MM-DD')
    }

    if (source.activities) {
      instance.activities = OpportunityActivity.toObjectList(source.activities)
    }

    if (source.files) {
      instance.files = ModelFile.toObjectList(source.files)
    }

    instance.metadata = {
      ...instance.metadata,
      ...source.metadata,
    }

    delete source.start_at
    delete source.end_at
    delete source.closed_at
    delete source.metadata
    delete source.activities

    Object.assign(instance, source)

    if (source.close_week_day === null) {
      instance.close_week_day = 'sunday'
    }

    /**
     * This was added due to the fact that the properties below are changed on set formAmount and
     * on page load this model has is_dirty = true
     */
    instance.amount = Number(instance.amount)

    if (instance.types.length == 1) {
      if (instance.types[0] == 'ssl') {
        instance.metadata.product_revenue_distribution.ssl = instance.amount
        instance.metadata.product_revenue_distribution.ccl = 0
      } else {
        instance.metadata.product_revenue_distribution.ssl = 0
        instance.metadata.product_revenue_distribution.ccl = instance.amount
      }
    }

    let total = Number(instance.sslAmount) + Number(instance.cclAmount)

    if (total == 0) {
      instance.metadata.product_revenue_distribution.ssl = instance.amount / 2
      instance.metadata.product_revenue_distribution.ccl = instance.amount / 2
    } else {
      instance.metadata.product_revenue_distribution.ssl = instance.amount * (instance.sslAmount / total)
      instance.metadata.product_revenue_distribution.ccl = instance.amount * (instance.cclAmount / total)
    }
    /** ******* */

    setTimeout(() => {
      instance.updateChecksum()
    }, 500)

    return instance
  }

  public static buildQuery(payload: any) {
    let api = new Api()
    return api.post('opportunities/report', payload)
  }

  public static buildAdvancedQuery(payload: any) {
    let api = new Api()
    return api.post('opportunities/advanced-report', payload)
  }

  public static weeklyCard(payload: any) {
    let api = new Api()
    return api.post('opportunities/weekly-card', payload)
  }

  public static weekPipeline(payload: any) {
    let api = new Api()
    return api.post('opportunities/weekly-pipeline', payload)
  }

  public static exportWeekClosedOpportunities(payload: any) {
    let api = new Api()
    return api.post('opportunities/weekly-closed-export', payload)
  }

  public getHistory(options: PaginateOptions) {
    const api = new Api()
    return api.post(`opportunity/${this.id}/history`, options)
  }

  public static forceStatusUpdate(payload: any, prop: string) {
    let api = new Api()
    const data: any = {}

    data[prop] = payload[prop]

    return api
      .post(`opportunities-update-status/${payload.id}`, data)
      .then(() => {
        WebMessage.success('Opportunity updated successfully')
      })
      .catch((error: any) => {
        throw error
      })
  }
}
